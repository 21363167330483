<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p>
          <i class="pi pi-fw pi-info-circle"></i>Se Gestiona todas las cartas
          fianzas de la Institución.
        </p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="open('new')"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Imprimir"
              icon="pi pi-print"
              class="p-button-help"
              @click="openPrint($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          selectionMode="single"
          dataKey="id"
          sortField="dias"
          :sortOrder="1"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cartas"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'numero',
            'nombreBanco',
            'nombreContratista',
            'nombreObra',
          ]"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <div>
                <label class="p-mb-3">Periodo: </label>
                <Dropdown
                  id="periodo"
                  :options="periodos"
                  placeholder="Seleccione un Periodo"
                  v-model="selectPeriodo"
                  autofocus
                  @change="listarCarta($event)"
                >
                </Dropdown>
              </div>
              <div>
                <label class="p-mb-3">Carpeta: </label>
                <Dropdown
                  id="id"
                  :options="carpetas"
                  optionLabel="nombre"
                  placeholder="Seleccione una carpeta"
                  v-model="selectCarpeta"
                  autofocus
                  @change="listarCarta($event)"
                >
                </Dropdown>
              </div>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="Carta" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">numero</span>
              <b>{{ slotProps.data.numero }}</b>
            </template>
          </Column>

          <Column field="fechaEmision" header="Fecha Emi." :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha Emi.</span>
              {{ slotProps.data.fechaEmision }}
            </template>
          </Column>

          <Column
            field="fechaVencimiento"
            header="Fecha Venc."
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Fecha Venc.</span>
              {{ slotProps.data.fechaVencimiento }}
            </template>
          </Column>

          <Column
            field="nombreContratista"
            header="Contratista/Obra/Banco"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Contratista/Obra/Banco</span>
              <b>{{ formatCurrency(slotProps.data.nombreContratista) }}</b
              >/{{ formatCurrency(slotProps.data.nombreObra) }}/<i
                style="color: purple"
                >{{ slotProps.data.nombreBanco }}
                {{ slotProps.data.telefonoBanco }}</i
              ><b style="color: darkcyan">
                Monto: S/ {{ formatPrice(slotProps.data.monto) }}</b
              >
            </template>
          </Column>
          <Column field="dias" header="Estado" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Estados</span>
              <span :style="{ color: formatEstadoCss(slotProps.data.dias) }">{{
                slotProps.data.dias
              }}</span>
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                class="p-button-rounded p-button-secondary p-mr-2"
                :style="{
                  'background-color': formatEstadoCss(slotProps.data.dias),
                }"
                @click="[itemClick(slotProps.data), toggle($event)]"
              />

              <Menu ref="menu" :model="items" :popup="true">
                <template #item="{ item }">
                  <Menuitem :rel="slotProps">
                    <li>{{ item.label }}</li>
                  </Menuitem>
                </template>
              </Menu>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '550px' }"
          header="Detalles del Documento"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Título</label>
            <InputText
              id="name"
              v-model.trim="product.titulo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.titulo }"
            />
            <small class="p-invalid" v-if="submitted && !product.titulo"
              >Título es requerido.</small
            >
          </div>
          <div class="p-field">
            <label for="description">Resumen</label>
            <Textarea
              id="description"
              v-model="product.resumen"
              required="true"
              rows="3"
              cols="20"
            />
          </div>
          <div class="p-field">
            <label>Fecha del documento </label>
            <Calendar
              :showIcon="true"
              :showButtonBar="true"
              v-model="product.fechaDocumento"
              dateFormat="dd/mm/yy"
              :class="'calendar-body'"
            ></Calendar>
          </div>

          <div class="p-field">
            <label for="etiquetaStatus" class="p-mb-3"
              >Etiqueta o Sección</label
            >

            <small
              class="p-invalid"
              v-if="submitted && !product.detalle[0].etiqueta.id"
              >Seleccione una etiqueta.</small
            >
          </div>

          <div class="p-field">
            <label class="p-mb-3">Documento</label>

            <FileUpload
              mode="basic"
              :customUpload="true"
              @uploader="myUploader"
              :fileLimit="1"
              :auto="true"
            />
            <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
            <small class="p-invalid" v-if="submitted && !filetitulo">
              Archivo es requerido.</small
            >
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Está seguro de querer borrar el documento:
              <b>{{ product.titulo }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="moverProductDialog"
          :style="{ width: '450px' }"
          header="Mover Carta Fianza "
          :modal="true"
          class="p-fluid"
        >
          <div class="confirmation-content">
            <span v-if="product"
              >Está seguro de querer mover la carta: <b>{{ product.numero }}</b
              >?</span
            >
            <div class="p-field">
              <Dropdown
                id="id"
                :options="carpetas"
                optionLabel="nombre"
                v-model="selectCarpetaMove"
                placeholder="Seleccione la carpeta"
                autofocus
                :class="{
                  'p-invalid': submitted && !selectCarpetaMove,
                }"
              >
              </Dropdown>
              <small
                class="p-invalid"
                style="color: red"
                v-if="submitted && !selectCarpetaMove"
                >Seleccione una carpeta para mover.</small
              >
            </div>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="moverProductDialog = false"
            />
            <Button
              label="Mover"
              icon="pi pi-check"
              class="p-button-text"
              @click="moverProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="displayVisor"
          :style="{ width: '1024px' }"
          header="Mover Carta Fianza "
          :modal="true"
          class="p-fluid"
        >
          <template #header>
            <h5>Visor de PDF carta: {{ selectedProducts.numero }}{{ load }}</h5>
          </template>
          <template style="display: block !important">
            <pdf
              :src="pdfdata"
              style="width: 100%; margin: 20px auto"
              v-for="i in numPages"
              :key="i"
              :id="i"
              :page="i"
            >
              <template style="display: block !important">
                loading content here..
              </template>
            </pdf>
          </template>

          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" />
            <Button label="Yes" icon="pi pi-check" autofocus />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="dialogPrint"
          :style="{ width: '850px' }"
          header="Seleccione los items tal cual desea imprimir"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label> Reporte: </label>

              <Dropdown
                id="reporte"
                :options="reportes"
                optionLabel="nombre"
                placeholder="Seleccione un Reporte"
                v-model="selectReporte"
                @change="seleccionReporte($event)"
              >
              </Dropdown>
            </div>
            <div class="p-field p-col">
              <label>Carpeta: </label>

              <Dropdown
                id="id"
                :options="carpetas"
                optionLabel="nombre"
                placeholder="Seleccione una carpeta"
                v-model="selectCarpetaPrint"
              >
              </Dropdown>
            </div>
          </div>

          <DataTable
            ref="dt"
            :value="printitems"
            v-model:selection="selecteds"
            selectionMode="multimple"
            dataKey="id"
            sortField="nombre"
            :sortOrder="1"
            :paginator="true"
            :rows="5"
            v-model:filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items"
            responsiveLayout="scroll"
            :globalFilterFields="['nombre']"
          >
            <template #header>
              <div
                class="
                  table-header
                  p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                "
              >
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar..."
                  />
                </span>
              </div>
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="nombre" header="Nombre" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Nombre</span>
                {{ formatCurrency(slotProps.data.nombre) }}
              </template>
            </Column>
          </DataTable>
          <template #footer>
            <a label="Imprimir" icon="pi pi-print" :href="link" target="_BLANK"  @click="imprimir()"  >Imprimir</a>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";
import pdf from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry"; // not needed since v1.9.1

export default {
  components: {
    pdf,
  },
  data() {
    return {
      selectPeriodo: -1,
      page: 1,
      periodos: null,
      selecteds: [],
      dialogPrint: false,
      selectCarpetaPrint: null,
      selectReporte: null,
      print: false,
      printitems: [],
      reportes: [
        { id: 1, nombre: "POR CONTRATISTA" },
        { id: 2, nombre: "POR BANCA O FINANCIERA" },
        { id: 3, nombre: "POR FECHA DE VENC. CONTRATISTA Y CARPETA"}
      ],
      numPages: 0,
      load: ", Espere mientras esta cargando el detalle en PDF...",
      errors: [],
      scale: "page-width",
      items: [
        {
          label: "Opciones",
          items: [
            {
              label: "Ver Carta",
              icon: "pi pi-fw pi-file-pdf",
              command: () => {
                this.load =
                  ", Espere mientras esta cargando el detalle en PDF...";
                this.viewProduct(this.selectedProducts);
              },
            },
            {
              label: "Editar",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                this.editProduct(this.selectedProducts);
              },
            },

            {
              label: "Mover a",
              icon: "pi pi-fw pi-folder",
              command: () => {
                this.confirmMoverProduct(this.selectedProducts);
              },
            },
            {
              label: "Borrar",
              icon: "pi pi-fw pi-trash",
              command: () => {
                this.deleteProduct(this.selectedProducts);
              },
            },
          ],
        },
      ],
      rutaPdf: "",
      displayVisor: false,
      varConfig: null,
      selectCarpeta: null,
      selectCarpetaMove: null,
      products: null,
      filetitulo: "",
      filetype: "",
      productDialog: false,
      moverProductDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      carpetas: {},
      link : "",
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombreObra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      submitted: false,
      action: "",
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
  },

  methods: {
    getPdf() {
      var self = this;
      self.pdfdata = pdf.createLoadingTask(this.rutaPdf);
      self.pdfdata.then((pdf) => {
        this.load = "";
        self.numPages = pdf.numPages;
        window.onscroll = function () {
          changePage();
          //stickyNav();
        };
        function changePage() {
          var i = 1,
            count = Number(pdf.numPages);
          do {
            if (
              window.pageYOffset >= self.findPos(document.getElementById(i)) &&
              window.pageYOffset <= self.findPos(document.getElementById(i + 1))
            ) {
              self.page = i;
            }
            i++;
          } while (i < count);
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i;
          }
        }
      });
    },
    itemClick(data) {
      this.selectedProducts = data;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatEstadoCss(value) {
      let tiempo = parseInt(value);
      if (tiempo == 0) {
        return "purple";
      } else if (tiempo < 0) {
        return "darkred";
      } else if (tiempo < 5 && tiempo > 0) {
        return "red";
      } else if (tiempo < 20 && tiempo > 5) {
        return "orange";
      } else {
        return "darkgreen";
      }
    },
    formatEstado(value) {
      let tiempo = parseInt(value);
      if (tiempo == 0) {
        return " Hoy Vence";
      } else if (tiempo < 0) {
        return (
          " Vecindo hace " +
          Math.abs(parseInt(value)) +
          (tiempo == 1 ? " día" : " días")
        );
      } else if (tiempo < 5 && tiempo > 0) {
        return "Venciendo en: " + value + (tiempo == 1 ? " día" : " días");
      } else if (tiempo < 20 && tiempo > 5) {
        return "Vence en: " + value + " días";
      } else {
        return "Quedan: " + value + " días";
      }
    },
    moment(fecha) {
      var fechaInicio = fecha.split("/");
      var fechadesde = new Date(
        fechaInicio[2],
        fechaInicio[1] - 1,
        fechaInicio[0]
      ).getTime();
      const today = new Date();
      //const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

      console.info(today + " /" + fechadesde);

      var dias = fechadesde - today;
      var diff_ = dias / (1000 * 60 * 60 * 24);
      return diff_;
    },
    myUploader(file) {
      /// console.info(file.files[0]);

      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.tipoArchivo = file.files[0].type;
              this.product.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    listarPeriodo() {
      this.$store
        .dispatch("gsfianza/CARTA_PERIODO")
        .then((res) => {
          this.periodos = res === undefined ? [] : res;
        })
        .catch((error) => {
          this.error(error);
        });
    },
    listarCarta() {
      let params = {
        carpeta: this.selectCarpeta.id,
        ejecutora: this.user.ejecutora,
        periodo: this.selectPeriodo,
      };
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/CARTA_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    config() {
      this.$store
        .dispatch("gsfianza/CONFIG")
        .then((res) => {
          this.varConfig = res;
        })
        .catch((error) => {
          this.error(error);
        });
    },
    listarData(e) {
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();

          this.$store
            .dispatch("gsfianza/CARPETA_LIST", e === undefined ? true : e)
            .then((res) => {
              this.carpetas = res;
              this.selectCarpeta = res[0];
              let params = {
                carpeta: this.selectCarpeta.id,
                ejecutora: this.user.ejecutora,
                periodo: this.selectPeriodo,
              };
              this.$store
                .dispatch("gsfianza/CARTA_LIST", params)
                .then((res) => {
                  this.isBusy = false;
                  this.$swal.close();
                  this.products = res === undefined ? [] : res;
                })
                .catch((error) => {
                  this.$swal.close();
                  this.isBusy = false;
                  this.error(error);
                });
            })
            .catch((error) => {
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
    formatCurrency(value) {
      if (value.length >= 80) {
        return value.substring(0, 80) + "...";
      } else {
        return value;
      }
    },
    open(operacion) {
      if (operacion == "new") {
        this.product = {
          ejecutora: this.user.ejecutora,
          estado: true,
        };

        console.info("new");
      }
      let mutacion = {
        carta: this.product,
        oper: operacion,
      };
      this.$store.commit("gsfianza/OPERACION_CARTA", mutacion);
      this.$router.push({
        name: "fianza/cartas/" + operacion,
      });
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    confirmMoverProduct(product) {
      this.moverProductDialog = true;
      this.submitted = false;
      this.product = { ...product };
      console.info(product);
    },
    moverProduct() {
      this.submitted = true;
      if (this.selectCarpetaMove) {
        this.moverProductDialog = false;
        this.isBusy = true;
        this.product.carpeta = this.selectCarpetaMove;
        this.$swal({
          width: 370,
          text: "Moviendo...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gsfianza/CARTA_MOVER", this.product)
              .then((res) => {
                this.isBusy = false;
                this.$swal.close();
                //this.listarData();
                this.products = this.products.filter(
                  (val) => val.id !== this.product.id
                );
                this.product = {};
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Carta movida, numero: " + res.numero,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                error(error);
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
    viewProduct(product) {
      this.displayVisor = true;
      this.rutaPdf = this.varConfig[0].valor + product.id;

      this.getPdf();
    },
    editProduct(product) {
      this.action = "gsfianza/CARTA_UPDATE";
      this.product = { ...product };
      this.product.fechaVencimiento = new Date(
        parseInt(this.product.fechaVencimiento.substring(6, 10)),
        parseInt(this.product.fechaVencimiento.substring(3, 5)) - 1,
        parseInt(this.product.fechaVencimiento.substring(0, 2))
      );
      this.product.fechaEmision = new Date(
        parseInt(this.product.fechaEmision.substring(6, 10)),
        parseInt(this.product.fechaEmision.substring(3, 5)) - 1,
        parseInt(this.product.fechaEmision.substring(0, 2))
      );
      this.filetitulo = "archivo" + this.product.extension;
      this.filetype = "[" + this.product.tipoArchivo + "]";
      this.open("edit");
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(product) {
      this.product = product;

      this.deleteProductDialog = false;
      this.isBusy = true;

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/CARTA_DELETE", this.product.id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = this.products.filter(
                (val) => val.id !== this.product.id
              );
              this.product = {};
              //this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Carta borrada, numero: " + res.numero,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },

    openPrint() {
      this.dialogPrint = true;
      this.print = false;
    },
    listarBanco(e) {
      this.isBussy = true;
      this.selecteds = [],
      this.printitems = [{id:1, nombre:"CARGANADO DATA. ESPERE PORFAVOR..."}]
      this.$store
        .dispatch("gsfianza/BANCO_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.printitems = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    listarContratista(e) {
      this.isBussy = true;
      let params = { ejecutora: e };
      this.selecteds = [],
      this.printitems = [{id:1, nombre:"CARGANADO DATA. ESPERE PORFAVOR..."}]
      this.$store
        .dispatch("gsfianza/CONTRATISTA_LIST", params)
        .then((res) => {
          this.isBussy = false;
          this.printitems = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    seleccionReporte(e) {
      if (e != null) {
        if (this.selectReporte.id == 1) {
          this.listarContratista(this.user.ejecutora);
        } else  if (this.selectReporte.id == 2) {
          this.listarBanco();
        } else {
          this.listarContratista(this.user.ejecutora);
        }
      }
    },
    imprimir(){
      
        let varId='';
        this.selecteds.forEach(function(x) {
              varId=varId+"&id="+x.id
            });
        this.link = this.varConfig[3].valor+"reporteServlet?format=pdf"+varId+"&report="+this.selectReporte.id+"&idc="+this.selectCarpetaPrint.id+"&nombrec="+this.selectCarpetaPrint.nombre;
      
    }
  },
  computed: {
    ...mapGetters({
      carta: "gsfianza/getModelCarta",
      user: "gsdialogoauth/getUser",
    }),
  },

  mounted() {
    if (this.carta.estado == false) {
      this.$router.push({
        name: "fianza/cartas",
      });
    }
    
    this.config();
    this.listarData();
    this.listarPeriodo();

    (this.$primevue.config.locale.monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]),
      (this.$primevue.config.locale.dayNamesMin = [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa",
      ]);
    this.$primevue.config.locale.clear = "Borrar";
    this.$primevue.config.locale.today = "Hoy";
    this.$primevue.config.locale.choose = "Elegir";
    this.$primevue.config.locale.upload = "Subir";
    this.$primevue.config.locale.cancel = "Cancelar";
  },
};
</script>
<style scoped lang="scss">
.calendar-body {
  height: 50px;
}
</style>
<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>

